import React from 'react'

export default function Activity() {
  return (
    <div>
      
      <div class="owl-carousel-wrapper">
        <div class="box-92819">
          <h1 class="text-warning text-center mb-3">Our Activities and Achivements</h1>
        </div>

        <div class="ftco-cover-1 overlay" style={{backgroundImage:"url(Gallary/img31.jpg)"}}>
        </div>
      </div>

        
    <div class="owl-carousel-wrapper">

      
      <div class="site-section site-section1">
        <div class="container ">
          <div class="heading-20219 mb-5">
            <h2 class="title text-cursive">ACTIVITIES AND ACHIVEMENTS</h2> <br/> <h6> (During The Year   2018-2019)</h6>
          </div>
          <hr/>

          <div class="row">
            <div class="col-md-4">
              <div class="event-29191 mb-5">
                <a href="#" class="d-block mb-3"><img src="Gallary/img50.jpg" alt="Image" class="img-fluid rounded"/></a>
                <div class=" d-flex">

                  <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                    <span class="text-white h3 m-0 d-block"></span>
                    <span class="text-white small">2018-2019</span>
                  </div>

                  <div>
                    <div class="mb-3  fontsm">
                      <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                      <span> <span class="icon-room mr-2 text-muted"></span> India</span>
                    </div>
                    <h3><a href="single.html">Target Intervention Project </a></h3>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="event-29191 mb-5">
                <a href="#" class="d-block mb-3"><img src="Gallary/img41.jpg" alt="Image" class="img-fluid rounded"/></a>
                <div class=" d-flex">

                  <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                    <span class="text-white h3 m-0 d-block"></span>
                    <span class="text-white small">2018-2019</span>
                  </div>

                  <div>
                    <div class="mb-3 fontsm">
                      <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                      <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                    </div>
                    <h3><a href="single.html">Consumer awareness program</a></h3>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="event-29191 mb-5">
                <a href="#" class="d-block mb-3"><img src="Gallary/cultu.jpg" alt="Image" class="img-fluid rounded"/></a>
                <div class=" d-flex">

                  <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                    <span class="text-white h3 m-0 d-block"></span>
                    <span class="text-white small">2018-2019</span>
                  </div>

                  <div>
                    <div class="mb-3 fontsm">
                      <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                      <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                    </div>
                    <h3><a href="single.html">Culture program for trible artisans</a></h3>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/img50.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3  fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span> India</span>
                      </div>
                      <h3><a href="single.html">Camps for Health Education </a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/img41.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Promoting De-addiction</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/yoga.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Providing Yoga and family Health Education</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/img50.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3  fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span> India</span>
                      </div>
                      <h3><a href="single.html">Implementation of Nai Roshni project  </a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/img41.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Operating family counsel center</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/mpower.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Empowerment and coaching to S.C. , S.T. and OBC communities</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/img54.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Handicraft and Skill development training to women</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/mpower.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Women empowerment training camps</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/handi.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Computer training center</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/img3.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Youth sports camp</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/aw.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Awareness camps for Women on mal-nutrition</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/road.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Road safety campaign</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/building.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Capacity building training to Panchayati Raj instructions</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/117.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Save water campaign</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/inti.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">National integration Day Program</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/106.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Training for adventurous sports</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/aw.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Awareness camps for protection and care against HIV/AIDS</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/127 - Copy.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Operating Day Care Centre for neglected senior citizens</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/112.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Celebrating Great Leaders Day</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/animal.jpg "  alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html">Animal welfare program</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/131.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                      </div>
                      <h3><a href="single.html" >Program for Ravine Erosion Control</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/helpingfarmer.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3 text-white"> <span class="icon-clock-o mr-2 text-white"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span class="text-white"> <span class="icon-room mr-2 "></span>India</span>
                      </div>
                      <h3><a href="single.html" class="text-white">Training for organic farming (jaiviki krishi)</a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/115.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3 text-white"> <span class="icon-clock-o mr-2 text-white"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span class="text-white"> <span class="icon-room mr-2 "></span>India</span>
                      </div>
                      <h3><a href="single.html" class="text-white">Awareness and training on Science & Technology </a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/133.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3 text-white"> <span class="icon-clock-o mr-2 text-white"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span class="text-white"> <span class="icon-room mr-2 "></span>India</span>
                      </div>
                      <h3><a href="single.html" class="text-white">Awareness camps for adolescents
                        </a></h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/101.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3 text-white"> <span class="icon-clock-o mr-2 text-white"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span class="text-white"> <span class="icon-room mr-2 "></span>India</span>
                      </div>
                      <h3><a href="single.html" class="text-white">Participation in Swachh Bharat Abhiyan
                        </a></h3>
                    </div>

                  </div>
                </div>
              </div><div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/road.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3 text-white"> <span class="icon-clock-o mr-2 text-white"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span class="text-white"> <span class="icon-room mr-2 "></span>India</span>
                      </div>
                      <h3><a href="single.html" class="text-white">Training to youth for Make-in-India program
                        </a></h3>
                    </div>

                  </div>
                </div>
              </div><div class="col-md-4">
                <div class="event-29191 mb-5">
                  <a href="#" class="d-block mb-3"><img src="Gallary/113.jpg" alt="Image" class="img-fluid rounded"/></a>
                  <div class=" d-flex">

                    <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                      <span class="text-white h3 m-0 d-block"></span>
                      <span class="text-white small">2018-2019</span>
                    </div>

                    <div>
                      <div class="mb-3 fontsm">
                        <span class="mr-3 text-white"> <span class="icon-clock-o mr-2 text-white"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span class="text-white"> <span class="icon-room mr-2 "></span>India</span>
                      </div>
                      <h3><a href="single.html" class="text-white">Implementation of program of Department of culture
                        </a></h3>
                    </div>

                  </div>
                </div>
              </div>
            </div><div class="col-md-4">
              <div class="event-29191 mb-5">
                <a href="#" class="d-block mb-3"><img src="Gallary/img50.jpg" alt="Image" class="img-fluid rounded"/></a>
                <div class=" d-flex">

                  <div class="bg-info p-3 d-inline-block text-center rounded mr-4 date">
                    <span class="text-white h3 m-0 d-block"></span>
                    <span class="text-white small">2018-2019</span>
                  </div>

                  <div>
                    <div class="mb-3 fontsm">
                      <span class="mr-3 text-white"> <span class="icon-clock-o mr-2 text-white"></span>9:30 AM &mdash; 11:30 AM</span>
                        <span class="text-white"> <span class="icon-room mr-2 "></span>India</span>
                    </div>
                    <h3><a href="single.html" class="text-white">Regular Annual Functions
                      </a></h3>
                  </div>

                </div>
              </div>
            </div>
            
          </div>
      </div>

      
    </div>
</div>



  )
}
