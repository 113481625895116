
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact() {


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('9340326142', 'template_ied7avf', form.current, 'BR-O9m-bN9EQJDUqm')
      .then((result) => {
          console.log(result.text);
          alert("successfull")
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div>
      <div class="owl-carousel-wrapper">
        <div class="box-92819">
          <h1 class="text-white text-center mb-3">Contact Us</h1>
          <p class="lead text-white">
            Thank you for your interest in our organization. We are always happy
            to hear from individuals and groups who share our passion for
            [insert the mission or cause of the NGO].
          </p>
        </div>

        <div class="ftco-cover-1 overlay" style={{backgroundImage:"url(Gallary/img57.jpg)"}}>
        </div>
      </div>

      <div class="site-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mb-5">
              <form ref={form} onSubmit={sendEmail}>
                <div class="form-group row">
                  <div class="col-md-6 mb-4 mb-lg-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      name="First_name"
                    />
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="last name"
                      name="Last_name"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email address"
                      name="user_email"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <textarea
                      
                      id=""
                      class="form-control"
                      placeholder="Write your message."
                      cols="30"
                      rows="10"
                      name="message"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mr-auto">
                    <input
                      type="submit"
                      class="btn btn-block btn-primary text-white py-3 px-5 rounded-0"
                      value="Send"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-4 ml-auto">
              <div class="bg-white p-3 p-md-5">
                <h3 class="text-cursive mb-4">Contact Info</h3>
                <ul class="list-unstyled footer-link">
                  <li class="d-block mb-3">
                    <span class="d-block text-muted small text-uppercase font-weight-bold">
                      Address:
                    </span>
                    <span>
                      H.No. 2 St.No.5 Coach Factory Road Dwarka Nagar Bhopal
                    </span>
                  </li>
                  <li class="d-block mb-3">
                    <span class="d-block text-muted small text-uppercase font-weight-bold">
                      TelePhone No.:
                    </span>
                    <span>+0755-2746332 </span>
                  </li>
                  <li class="d-block mb-3">
                    <span class="d-block text-muted small text-uppercase font-weight-bold">
                      Phone:
                    </span>
                    <span>91+ 8109511876 , 9303102407</span>
                  </li>
                  <li class="d-block mb-3">
                    <span class="d-block text-muted small text-uppercase font-weight-bold">
                      Email:
                    </span>
                    <span>dwarka06@rediffmail.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="site-section">
        <div class="container">
          <div class="d-md-flex cta-20101 align-self-center bg-light p-5">
            <div class="">
              <h2 class="text-cursive">
                Helping the Homeless, Hungry, and Hurtings Women & Children's
              </h2>
            </div>
            <div class="ml-auto">
              <a href="donate" class="btn btn-primary">
                Donate Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
