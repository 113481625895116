import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


export default function Footer() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('9340326141', 'template_jas6gow', form.current, 'BR-O9m-bN9EQJDUqm')
      .then((result) => {
          console.log(result.text);
          alert("successfull")
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <div>
    <footer class="site-footer bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-7">
                <h2 class="footer-heading mb-4">About Us</h2>
                <p class="text-justify">Dwarka women child care society is a non- government, non-profit voluntary organization, operating a National level for more than 13 years now and continues to devote and commit itself for the creation of self- reliant People in terms of education, health, livelihood and governance with emphasis on securing active participation of all stakeholders and gender equality in the process of development.</p>

              </div>
              <div class="col-md-4 ml-auto">
                <h2 class="footer-heading mb-4">Features</h2>
                <ul class="list-unstyled">
                  <li><a href="about">About Us</a></li>
                  <li><a href="events">Events</a></li>
                  <li><a href="activity">Our Activities</a></li>
                  <li><a href="contact">Contact Us</a></li>
                  <li><a href="becomeamember">Become Member</a></li>
                  <li ><a href="donate" > Donate Now !!!.. </a></li>
                  
                </ul>
              </div>

            </div>
          </div>
          <div class="col-md-4 ml-auto">

            <div class="mb-5">
              <h2 class="footer-heading mb-4">Get Notify for upcomming Events</h2>
              <form class="footer-suscribe-form" ref={form} onSubmit={sendEmail}>
                <div class="input-group mb-3">
                  <input type="text" name="email" class="form-control rounded-0 border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2"/>
                  <div class="input-group-append">
                    <button class="btn btn-info text-white" name="notifyme" type="submit" id="button-addon2">Notify Me</button>
                  </div>
                </div>
            


            <h2 class="footer-heading mb-4">Follow Us</h2>
            <a href="https://www.facebook.com/dwarkacare?mibextid=ZbWKwL" class="smoothscroll pl-0 pr-3"><span class="icon-facebook"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-twitter"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-instagram"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-linkedin"></span></a>
            </form>
          </div>
        </div>
        
        
    </div>  
    </div>
    </footer>
    <div class="container-fluid bg-info">
      <div class="row pt-2 mt-2 text-center ">
        <div class="col-md-12 ">
          <div class="pt-2 text-white">
            <p>
          Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved |  <i class="icon-heart text-danger" aria-hidden="true"></i> By <a href="#" target="_blank"  ><span class="text-white">  DWARKA WOMAN CHILD CARE SOCIETY </span></a>
          </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
