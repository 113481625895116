import React from 'react'

export default function Slider() {
  return (
    <div>




    <div class="site-wrap container-fluid " id="home-section">
      {/* <div class="row">
        <div class="col-md-12">
          <div class="site-mobile-menu site-navbar-target">
            <div class="site-mobile-menu-header">
              <div class="site-mobile-menu-close mt-3">
                <span class="icon-close2 js-menu-toggle"></span>
              </div>
            </div>
            <div class="site-mobile-menu-body"></div>
          </div>
        </div>
      </div> */}
    <div class="owl-carousel-wrapper">

      

      <div class="box-92819">
        {/* <!-- <h1 class="text-danger mb-3">Join The Movement To end Child Poverty</h1> -->
        <!-- <h1 class="text-warning mb-3">Welcome To Dwarka Women Child care society</h1> -->
        <!-- <p><a href="donate.html" class="btn btn-info py-3 px-4 rounded-0">Donate Now</a></p> --> */}
      </div>

      <div class="owl-carousel owl-1 ">

      <div class="ftco-cover-1 overlay " style={{backgroundImage: "url(Gallary/img50.jpg)"}}></div>
        <div class="ftco-cover-1 overlay" style={{backgroundImage: "url(Gallary/img32.jpg)"}}></div>
        <div class="ftco-cover-1 overlay " style={{backgroundImage: "url(Gallary/img39.jpg)"}}></div>

        
      </div>
    </div>
    

    <div class="container">
    <div class="feature-29192-wrap d-md-flex" style={{marginTop: "-50px", position: "relative", zIndex: "2"}}>


        <a href="#" class="feature-29192 overlay-danger" style={{backgroundImage: "url(images/blog1.jpg)"}}>
          <div class="text">
            <span class="meta text-danger">Livelihood</span>
            <h3 class="text-cursive text-success h1">Livelihood</h3>
          </div>
        </a>

        <a class="feature-29192 overlay-success" style={{backgroundImage: "url(Gallary/img18.jpg)"}}>
          <div class="text">
            <span class="meta">Health</span>
            <h3 class="text-cursive text-warning h1">Natural Remedies</h3>
          </div>
        </a>

        <div class="feature-29192 overlay-warning" style={{backgroundImage: "url(Gallary/img27.jpg)"}}>
          <div class="text">
            <span class="meta text-warning">School</span>
            <h3 class="text-cursive text-success h1">New Class Rooms</h3>
          </div>
        </div>

      </div>
    </div>
    </div>
</div>
 

  )
}
