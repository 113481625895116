import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./page/Home";
import Header from "./module/Header";
import Footer from "./module/Footer";
import About from "./page/About";
import Activity from "./page/Activity";
import Events from "./page/Events";
import Contact from "./page/Contact";
import Becomeamember from "./page/Becomeamember";
import Donate from "./page/Donate";
import Abc from "./page/Abc";
import Beneficiary from "./page/Beneficiary";


// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

function App() {  


  return (
    <>
    <Header/>




    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="about" element={<About />} /> 
        <Route path="activity" element={<Activity />} />
        <Route path="events" element={<Events />} />
        <Route path="contact" element={<Contact />} />
        <Route path="becomeamember" element={<Becomeamember />} />
        <Route path="donate" element={<Donate />} />
        <Route path="beneficiary" element={<Beneficiary />} />
        <Route path="link1" element={<Abc/>} />
        
        
        
      </Routes>
    </BrowserRouter>






    <Footer/>
    
  {/* Call the function to add a library */}
  
  {AddLibrary(
  'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')}
 
  
  
    </>
  );
}

export default App;
