import React from 'react'

export default function Target() {
  return (
    <div>
      <div class="site-section site-section2">
      <div class="container">
        
        <div class="row mb-5 align-items-st">
          <div class="col-md-12">
            <div class="heading-20219">
              <h2 class="title text-cursive"> Target Beneficiaries / Stakeholders</h2>
            </div>
            <hr/>
          </div>
         
        </div>

        <div class="row">
          <div class="col-md-4">
              <div class="cause shadow-sm">
              
                <a href="#" class="cause-link d-block">
                  <img src="Gallary/helpingfarmer.jpg" alt="Image" class="img-fluid"/>
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-danger" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class=" pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-primary ml-3 py-1 small px-2 rounded mb-3 d-inline-block">Small and marginal farmers</span>
                  <h3 class="mb-4  ml-3"><a href="#">We are excited to invite you to our upcoming Health Fair, where we aim to promote health and wellness in the community. This event is an opportunity for everyone to learn about common health issues, receive free health screenings, and connect with local health providers.</a></h3>
                  
                </div>
              
              </div>
          </div>
          <div class="col-md-4">
            
            <div class="cause shadow-sm">
              
                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img11.jpg" alt="Image" class="img-fluid"/>
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress secondary" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-info py-1 small px-2 rounded mb-3 d-inline-block">Women and children</span>
                  <h3 class="mb-4"><a href="#"> An event aimed at spreading awareness about the NGO's cause, and educating people on the issues it addresses. This could involve seminars, conferences, or exhibitions.</a></h3>
                  
                </div>
              
              </div>

          </div>
          <div class="col-md-4">
            
            <div class="cause shadow-sm">
              
                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img38 - Copy.jpg" alt="Image" class="img-fluid"/>
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-warning" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border ">
                  <span class="badge-warning py-1 small px-2 rounded mb-3 d-inline-block">Drug addicts</span>
                  <h3 class="mb-4"><a href="#"> An event aimed at providing skills and knowledge to individuals or groups that are relevant to the NGO's mission. This could involve training sessions on topics like human rights, environmental conservation, or social entrepreneurship.</a></h3>
                  
                </div>
              
              </div>

          </div>
        </div>
        <a href="beneficiary">
          <div class="form-group text-center ">
          <input type="submit" value="Know More" class="btn btn-info"/>
        </div></a>
        
      </div>
    </div>
    </div>
  )
}
