import React from 'react'

export default function Achivement() {
  return (
    <div>
          <div class="site-section ">
      <div class="container ">
        <div class="heading-20219 mb-5">
          <h2 class="title text-cursive">ACTIVITIES AND ACHIVEMENTS</h2> <br/> <h6> (During The Year   2018-2019)</h6>
        </div>
        <hr/>

        <div class="row">
          <div class="col-md-4">
            <div class="event-29191 mb-5">
              <a href="#" class="d-block mb-3"><img src="Gallary/img50.jpg" alt="Image" class="img-fluid rounded"/></a>
              <div class=" d-flex">

                <div class="secondary p-3 d-inline-block text-center rounded mr-4 date">
                  <span class="text-white h3 m-0 d-block"></span>
                  <span class="text-white small">2018-2019</span>
                </div>

                <div>
                  <div class="mb-3  fontsm">
                    <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                    <span> <span class="icon-room mr-2 text-muted"></span> India</span>
                  </div>
                  <h3><a href="single">Target Intervention Project </a></h3>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="event-29191 mb-5">
              <a href="#" class="d-block mb-3"><img src="Gallary/img41.jpg" alt="Image" class="img-fluid rounded"/></a>
              <div class=" d-flex">

                <div class="secondary p-3 d-inline-block text-center rounded mr-4 date">
                  <span class="text-white h3 m-0 d-block"></span>
                  <span class="text-white small">2018-2019</span>
                </div>

                <div>
                  <div class="mb-3 fontsm">
                    <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                    <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                  </div>
                  <h3><a href="single">Consumer awareness program</a></h3>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="event-29191 mb-5">
              <a href="#" class="d-block mb-3"><img src="Gallary/cultu.jpg" alt="Image" class="img-fluid rounded"/></a>
              <div class=" d-flex">

                <div class="secondary p-3 d-inline-block text-center rounded mr-4 date">
                  <span class="text-white h3 m-0 d-block"></span>
                  <span class="text-white small">2018-2019</span>
                </div>

                <div>
                  <div class="mb-3 fontsm">
                    <span class="mr-3"> <span class="icon-clock-o mr-2 text-muted"></span>9:30 AM &mdash; 11:30 AM</span>
                    <span> <span class="icon-room mr-2 text-muted"></span>India</span>
                  </div>
                  <h3><a href="single">Culture program for trible artisans</a></h3>
                </div>

              </div>
            </div>
          </div>
          
        </div>
        <a href="activity"><div class="form-group text-center ">
          <input type="submit" value="Know More" class="btn btn-info"/>
        </div></a>
      </div>
    </div>
    </div>
   
  )
}
