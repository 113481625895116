import React from 'react'

import Slider from "../module/Slider";
import Achivement from "../module/Achivement";
import Mission from "../module/Mission";
import Target from "../module/Target";
import Donatenow from "../module/Donatenow";

export default function Home() {
  return (
    <div>
        
        <Slider/>
        <Achivement/>
        <Mission/>
        <Target/>
        <Donatenow/>
       
    </div>
  )
}
