import React from 'react'

export default function Header() {
  return (
    <div>
    <div class="site-wrap container-fluid " id="home-section">
      <div class="row">
        <div class="col-md-12">
          <div class="site-mobile-menu site-navbar-target">
            <div class="site-mobile-menu-header">
              <div class="site-mobile-menu-close mt-3">
                <span class="icon-close2 js-menu-toggle"></span>
              </div>
            </div>
            <div class="site-mobile-menu-body"></div>
          </div>
        </div>
      </div>
      
      
      
      
      <div class="bg-info py-2 top-bar shadow d-none d-md-block sticky-top">
        <div class="container ">
          <div class="row">
            <div class="col-md-6 text-md-left pl-0 topnav">
              <a href="/" class=" pr-3 pl-0">Home</a>
              {/* <!-- <a href="events" class="p-3">Events</a> --> */}
              <a href="becomeamember" class="p-3">Become A Volunteer</a>
              <a href="donate" class="p-3 donor blink">Donate Now !!</a>
            </div>
            <div class="col-md-6 text-md-right topsocial">
            <a href="https://www.facebook.com/dwarkacare?mibextid=ZbWKwL" class="smoothscroll pl-0 pr-3"><span class="icon-facebook"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-twitter"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-instagram"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-linkedin"></span></a>
            </div>
          </div>
        </div>
      </div>
      
      <div class="container"></div>
      
      <header class="site-navbar site-navbar-target bg-dark shadow  sticky-top px-2" role="banner">

        <div class="container  ">
          <div class="row align-items-center position-relative">
            

            <div class="site-logo">
              <a href="index" class=" text-white text-uppercase">DWARKA WOMAN CHILD CARE SOCIETY</a>
            </div>


            <nav class="site-navigation text-left ml-auto " role="navigation">
              <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                <li class=""><a href="/" class="nav-link">Home</a></li>
                <li><a href="about" class="nav-link">About Us</a></li>
                <li><a href="activity" class="nav-link">Our Activities</a></li>
                <li><a href="events" class="nav-link"> Event's</a></li>
                {/* <li class="disp"><a href="events" class="nav-link">Events</a></li> */}
                <li class="disp"><a href="becomeamember" class="nav-link">Become a Member</a></li>
                <li><a href="contact" class="nav-link">Contact</a></li>
                <li class="disp"><a href="donate" class="nav-link">Donate Now !!!</a></li>
                {/* <li>
                    <a href="donate" class="nav-link">
                    <div class="dropdown">
                        <button type="button" class="btn text-white dropdown-toggle" data-toggle="dropdown">
                          Visit
                        </button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="Link1">Link 1</a>
                          <a class="dropdown-item" href="Link2">Link 2</a>
                          <a class="dropdown-item" href="Link3">Link 3</a>
                        </div>
                    </div>
                    </a>
                    
                </li> */}
              </ul>
            </nav>

            <div class="ml-auto toggle-button d-inline-block d-lg-none"><a href="#" class="site-menu-toggle py-5 js-menu-toggle text-white"><span class="icon-menu h3 text-white"></span></a></div>

            

          </div>
        </div>

      </header>




    <div class="owl-carousel-wrapper"></div>
      
    </div>
    </div>
  )
}
