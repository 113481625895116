import React from 'react'

export default function Donatenow() {
  return (
    <div>
            <div class="site-section bg-image overlay-primary" style={{backgroundImage:" url(images/img_1.jpg)"}}>
      <div class="container">
        <div class="row align-items-stretch">
          <div class="col-md-6">
            <img src="Gallary/img26.jpg" alt="Image" class="img-fluid shadow"/>
          </div>
          <div class="col-md-6">
            <div class="bg-white h-100 p-4 shadow">
              <h3 class="mb-4 text-cursive">Donate Now</h3>
              <form action="#">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Name"/>
                </div>
                <div class="form-group">
                  <input type="email" class="form-control" placeholder="Email"/>
                </div>
                <div class="form-group">
                  <select class="form-control">
                    <option class="text-muted disabled">Select </option>
                    <option>Rs.  500</option>
                    <option>Rs.  1,000</option>
                    <option>Rs.  1,500</option>
                    <option>Rs.  2,000</option>
                  </select>              
                </div>
                <div class="form-group">
                  <a href="donate"><input type="button" value="Donate Now" class="btn btn-info"/></a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="site-section">
      <div class="container">
        
            <div class="d-md-flex cta-20101 align-self-center bg-light p-5">
              <div class=""><h2 class="text-cursive">Helping the Homeless, Hungry, and Hurtings Women & Children's</h2></div>
              <div class="ml-auto"><a href="donate" class="btn btn-info">Donate Now</a></div>
            </div>
        
      </div>
    </div>

    </div>
  )
}
