import React from "react";

export default function Becomeamember() {
  return (
    <div>
      <div class="site-section bg-image123 overlay-primary mx-3 mt-4">
        <div class="container">
          <div class="row align-items-stretch">
            <div class="col-md-6">
              <img
                src="Gallary/img26.jpg"
                alt="Image"
                class="img-fluid shadow"
              />
            </div>
            <div class="col-md-6">
              <div class="bg-white h-100 p-4 shadow">
                <h3 class="mb-4 text-cursive">Become A Member</h3>
                <form action="#">
                  <div class="form-group">
                    <input
                      type="text"
                      
                      class="form-control"
                      placeholder="Name Your Name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Mobile Number"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Id Card No."
                    />
                  </div>
                  <div class="form-group">
                    <select class="form-control" placeholder="Select id">
                      <option>Select Your Id</option>
                      <option >Aadhar Card</option>
                      <option >Pan Card</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      type="file"
                      
                      class="form-control"
                      placeholder="select"
                    />
                  </div>
                  <div class="form-group">
                    <a href="donate">
                      <input
                        type="button"
                        value="Submit"
                        class="btn btn-primary"
                      />
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="site-section">
        <div class="container">
          <div class="d-md-flex cta-20101 align-self-center bg-light p-5">
            <div class="">
              <h2 class="text-cursive">
                Helping the Homeless, Hungry, and Hurtings Women & Children's
              </h2>
            </div>
            <div class="ml-auto">
              <a href="donate" class="btn btn-primary">
                Donate Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
