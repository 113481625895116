import React from 'react'

export default function Mission() {
  return (
    <div>
        <div class="bg-image overlay site-section" style={{backgroundImage: "url(gallary/img32.jpg)"}} id="ourmission">
      <div class="container">

        <div class="row align-items-center">
          <div class="col-12">
            <div class="row mb-5">
              <div class="col-md-12">
                <div class="heading-20219">
                  <h2 class="title text-white mb-4 text-cursive">Our Mission's</h2>
                  <p class="text-white text-justify offset-1"> With the underlying objective and mission to devote and dedicate our efforts for creation of self-reliant women’s and Girls, by good Health & Education the mission of our organization is:
                   <br/> “ To work with rural youth and women aimed at alleviating the indigence of women by using various ways or urban and rural development our integrated approach includes need base Health and Education, watershed and agriculture education and extension for rural and urban empowerment . we believe that development in youths need to be a process which ensure qualitative improvement in the people’s access, security and status in their socioeconomics, political and physical situations, and he opportunities to live in dignity, freedom and justice.
                    </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-5">
                <div class="feature-29012 d-flex text-justify">
                  <div class="number mr-4"><span>1</span></div>
                  <div>
                    <h3>Awareness and Empower</h3>
                    <p>To create awareness and empower the community to realize their entitlements of land water forest employment and civil rights:</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div class="feature-29012 d-flex text-justify">
                  <div class="number mr-4"><span>2</span></div>
                  <div>
                    <h3>Nisi Sint Explicabo</h3>
                    <p>To sustain communities action for the operation and maintenance of the assets created and develop further the potential of natural resources;</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 mb-5">
                <div class="feature-29012 d-flex text-justify">
                  <div class="number mr-4"><span>3</span></div>
                  <div>
                    <h3>Utilization of  skills</h3>
                    <p>	To build capacities of community members for maximum utilization of local skills and resources for its best use as well as guide people regarding, their health, equity and all other rights:</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div class="feature-29012 d-flex text-justify">
                  <div class="number mr-4"><span>4</span></div>
                  <div>
                    <h3>Political empowerment</h3>
                    <p>Strive for ensuring social economics, educational and political empowerment of the women, children and socially, economically backward people;</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div class="feature-29012 d-flex text-justify">
                  <div class="number mr-4"><span>5</span></div>
                  <div>
                    <h3>Official Communication</h3>
                    <p>To help establish institutional linkages between peoples and government departments, autonomous institution financial institution for enhancing access to information scheme and services and investment in peoples rural transformation plans and programs;</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div class="feature-29012 d-flex text-justify">
                  <div class="number mr-4"><span>6</span></div>
                  <div>
                    <h3>Providing Plateform's</h3>
                    <p>To provide platform for developing strategic plans avenues for skill development and up gradation, building market and credit linkages for achieving decent and sustainable livelihood of the target rural communities;</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div class="feature-29012 d-flex text-justify">
                  <div class="number mr-4"><span>7</span></div>
                  <div>
                    <h3>Collaboration's</h3>
                    <p>To develop strategic partnerships, alliances and collaboration with international and national donors, funding agencies, NGOs, research academic and training institution for materializing the mission of the organization.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
