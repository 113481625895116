import React from 'react'

export default function About() {
  return (

    <div>

        
        
            <div class="owl-carousel-wrapper">
                <div class="box-92819">
                    <h1 class="text-white mb-3 ">About Us</h1>
                </div>
                <div class="ftco-cover-1 overlay"  style={{backgroundImage:"url(Gallary/img39.jpg)"}} > </div>
                <div class="site-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                            <img src="Gallary/img26.jpg" alt="Image" class="img-fluid"/>
                            </div>
                            <div class="col-md-5 ml-auto">
                            <h3 class="text-cursive mb-4">Our Mission</h3>
                            <p class="text-justify">“ To work with rural youth and women aimed at alleviating the indigence of women by using various ways or urban and rural development our integrated approach includes need base Health and Education, watershed and agriculture education and extension for rural and urban empowerment . we believe that development in youths need to be a process which ensure qualitative improvement in the people’s access, security and status in their socioeconomics, political and physical situations, and he opportunities to live in dignity, freedom and justice.</p>
                            <p>A minima eos optio cum, veniam repellat autem, aperiam rem dolor asperiores. Debitis dolores eligendi in adipisci suscipit!</p> 
                            <p><a href="Events" class="btn btn-info rounded-0 px-4">Know More</a></p>
                            </div>
                        </div>
                    </div>
                </div> 
                
            </div>
            <hr/>



        <div class="site-section">
            <div class="container">
            
                <div class="d-md-flex cta-20101 align-self-center bg-light p-5">
                    <div class=""><h2 class="text-cursive">Helping the Homeless, Hungry, and Hurtings Women & Children's</h2></div>
                    <div class="ml-auto"><a href="donate" class="btn btn-info">Donate Now</a></div>
                </div>
            
            </div>
        </div>

        <hr class="text-line-info"/>
    </div>    


  
   
  )
}
