import React from 'react'

export default function Events() {
  return (
    <div>



      <div class="owl-carousel-wrapper">



        <div class="box-92819">
          <h1 class="text-white mb-3 text-center">Our Events</h1>
        </div>


        <div class="ftco-cover-1 overlay" style={{backgroundImage:"url(Gallary/img20.jpg)"}}></div>

      </div>


      <div class="site-section">
        <div class="container">

          <div class="row">
            <div class="col-md-4">
              <div class="cause shadow-sm">

                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img29.jpg" alt="Image" class="img-fluid" />
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-danger" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-danger py-1 small px-2 rounded mb-3 d-inline-block">School</span>
                  <h3 class="mb-4"><a href="#">Education and supporting the development of high-quality school content.</a></h3>
                  <div class="border-top border-light border-bottom py-2 d-flex">
                    <div>Donated</div>
                    <div class="ml-auto"><strong class="text-primary">RS 00</strong></div>
                  </div>

                  <div class="py-4">
                    <div class="d-flex align-items-center">
                      <img src="Gallary\usericon.png" alt="Image" class="rounded-circle mr-3" width="50" />
                      <div class="">Rahul</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-4">

              <div class="cause shadow-sm">

                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img36.jpg" alt="Image" class="img-fluid" />
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-primary" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-primary py-1 small px-2 rounded mb-3 d-inline-block">Health</span>
                  <h3 class="mb-4"><a href="#">We provide primary healthcare services to underserved communities, including preventative care, health education, and treatment for common illnesses.</a></h3>
                  <div class="border-top border-light border-bottom py-2 d-flex">
                    <div>Donated</div>
                    <div class="ml-auto"><strong class="text-primary">RS 00</strong></div>
                  </div>

                  <div class="py-4">
                    <div class="d-flex align-items-center">
                      <img src="Gallary\usericon.png" alt="Image" class="rounded-circle mr-3" width="50" />
                      <div class="">Ajay kumar Patel</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="col-md-4">

              <div class="cause shadow-sm">

                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img22.jpg" alt="Image" class="img-fluid" />
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-warning" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border ">
                  <span class="badge-warning py-1 small px-2 rounded mb-3 d-inline-block">Livelihood</span>
                  <h3 class="mb-4"><a href="#">We support the development of sustainable livelihoods and economic opportunities in communities.</a></h3>
                  <div class="border-top border-light border-bottom py-2 d-flex">
                    <div>Donated</div>
                    <div class="ml-auto"><strong class="text-primary">RS 00</strong></div>
                  </div>

                  <div class="py-4">
                    <div class="d-flex align-items-center">
                      <img src="Gallary\usericon.png" alt="Image" class="rounded-circle mr-3" width="50" />
                      <div class="">Ramesh Pathak</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>


            <div class="col-md-4">
              <div class="cause shadow-sm">

                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img35.jpg" alt="Image" class="img-fluid" />
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-danger" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-danger py-1 small px-2 rounded mb-3 d-inline-block">Birthday Celebration</span>
                  <h3 class="mb-4"><a href="#">NGOs may celebrate birthdays in different ways, depending on their mission, goals, and resources. </a></h3>
                  <div class="border-top border-light border-bottom py-2 d-flex">
                    <div>Donated</div>
                    <div class="ml-auto"><strong class="text-primary">RS 00</strong></div>
                  </div>

                  <div class="py-4">
                    <div class="d-flex align-items-center">
                      <img src="Gallary\usericon.png" alt="Image" class="rounded-circle mr-3" width="50" />
                      <div class="">Rahul</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-4">

              <div class="cause shadow-sm">

                <a href="#" class="cause-link d-block">
                  <img src="Gallary/130.jpg" alt="Image" class="img-fluid" />
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-primary" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-primary py-1 small px-2 rounded mb-3 d-inline-block">Swachh Bharat Abhiyan</span>
                  <h3 class="mb-4"><a href="#">Swachh Bharat Abhiyan is a noble initiative launched by the Indian Government to make India clean and hygienic.</a></h3>
                  <div class="border-top border-light border-bottom py-2 d-flex">
                    <div>Donated</div>
                    <div class="ml-auto"><strong class="text-primary">RS 00</strong></div>
                  </div>

                  <div class="py-4">
                    <div class="d-flex align-items-center">
                      <img src="Gallary\usericon.png" alt="Image" class="rounded-circle mr-3" width="50" />
                      <div class="">Rahul</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="col-md-4">

              <div class="cause shadow-sm">

                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img4.jpg" alt="Image" class="img-fluid" />
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-warning" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border ">
                  <span class="badge-warning py-1 small px-2 rounded mb-3 d-inline-block">Painting</span>
                  <h3 class="mb-4"><a href="#">A painting competition organized by an NGO can serve as a platform for artists to showcase their talent while also raising awareness and funds for a cause supported by the NGO.</a></h3>
                  <div class="border-top border-light border-bottom py-2 d-flex">
                    <div>Donated</div>
                    <div class="ml-auto"><strong class="text-primary">RS 00</strong></div>
                  </div>

                  <div class="py-4">
                    <div class="d-flex align-items-center">
                      <img src="Gallary\usericon.png" alt="Image" class="rounded-circle mr-3" width="50" />
                      <div class="">Rahul</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-12">
              <span class="p-3">1</span>
              <a href="#" class="p-3">2</a>
              <a href="#" class="p-3">3</a>
              <a href="#" class="p-3">4</a>
            </div>



          </div>
        </div>
      </div>




      <div class="site-section">
        <div class="container">

          <div class="d-md-flex cta-20101 align-self-center bg-light p-5">
            <div class=""><h2 class="text-cursive">Helping the Homeless, Hungry, and Hurtings Women & Children's</h2></div>
            <div class="ml-auto"><a href="donate" class="btn btn-primary">Donate Now</a></div>
          </div>

        </div>
      </div>
    </div>
  )
}
