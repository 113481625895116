import React from 'react'

export default function Abc() {
  return (
    <div>
      
      <div class="site-section">
            <div class="container">
            
                <div class="d-md-flex cta-20101 align-self-center bg-light p-5">
                    <div class=""><h2 class="text-cursive">Helping the Homeless, Hungry, and Hurtings Women & Children's</h2></div>
                    <div class="ml-auto"><a href="donate.html" class="btn btn-info">Donate Now</a></div>
                </div>
            
            </div>
        </div>
    </div>
  )
}
