import React from 'react'

export default function Beneficiary() {
  return (
    <div>
      
        

      <div class="">
        <div class="box-92819">
          <h1 class="text-white">Target Beneficiaries </h1>
         
        </div>
        <div class="overlay" style={{backgroundmage: "url('Gallary/road.jpg)"}}></div>
        
      </div>
    

    



    <div class="site-section site-section2">
      <div class="container">
        
        <div class="row mb-5 align-items-st">
          <div class="col-md-12">
            <div class="heading-20219">
              <h2 class="title text-cursive"> Target Beneficiaries / Stakeholders</h2>
            </div>
            <hr/>
          </div>
          
        </div>

        <div class="row">
          <div class="col-md-4">
              <div class="cause shadow-sm">
              
                <a href="#" class="cause-link d-block">
                  <img src="Gallary/helpingfarmer.jpg" alt="Image" class="img-fluid"/>
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-danger" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class=" pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-primary ml-3 py-1 small px-2 rounded mb-3 d-inline-block">Small and marginal farmers</span>
                  <h3 class="mb-4  ml-3"><a href="#">Small and marginal farmers are agricultural producers who have limited resources in terms of land, labor, capital, and technology. Small farmers typically own or cultivate less than two hectares of land, while marginal farmers have less than one hectare of land.</a></h3>
                </div>
              
              </div>
          </div>
          <div class="col-md-4">
            
            <div class="cause shadow-sm">
              
                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img11.jpg" alt="Image" class="img-fluid"/>
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-info" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border shadow-sm">
                  <span class="badge-info py-1 small px-2 rounded mb-3 d-inline-block">Women and children</span>
                  <h3 class="mb-4"><a href="#"> NGOs (Non-Governmental Organizations) that focus on the welfare and rights of women and children are important entities that work towards creating a more equitable and just society.</a></h3>
                  
                </div>
              
              </div>

          </div>
          <div class="col-md-4">
            
            <div class="cause shadow-sm">
              
                <a href="#" class="cause-link d-block">
                  <img src="Gallary/img38 - Copy.jpg" alt="Image" class="img-fluid"/>
                  <div class="custom-progress-wrap">
                    <span class="caption">80% complete</span>
                    <div class="custom-progress-inner">
                      <div class="custom-progress bg-warning" style={{width: "80%"}}></div>
                    </div>
                  </div>
                </a>

                <div class="px-3 pt-3 border-top-0 border border ">
                  <span class="badge-warning py-1 small px-2 rounded mb-3 d-inline-block">Drug addicts</span>
                  <h3 class="mb-4"><a href="#"> Drug addiction is a complex issue that affects individuals, families, and communities. Programs that aim to help individuals overcome drug addiction can vary widely in their approach and effectiveness.</a></h3>
                  
                </div>
              
              </div>

          </div>
          <div class="col-md-4">
            <div class="cause shadow-sm">
            
              <a href="#" class="cause-link d-block">
                <img src="Gallary/106.jpg" alt="Image" class="img-fluid"/>
                <div class="custom-progress-wrap">
                  <span class="caption">80% complete</span>
                  <div class="custom-progress-inner">
                    <div class="custom-progress bg-danger" style={{width: "80%"}}></div>
                  </div>
                </div>
              </a>

              <div class=" pt-3 border-top-0 border border shadow-sm">
                <span class="badge-primary ml-3 py-1 small px-2 rounded mb-3 d-inline-block">Women of minority communities</span>
                <h3 class="mb-4  ml-3"><a href="#">Women from minority communities often face unique challenges and experiences that are shaped by their racial, ethnic, religious, or linguistic identities. These challenges can include discrimination, prejudice, social exclusion, and limited access to resources and opportunities. As a result, many organizations and NGOs have emerged to support the empowerment and advancement of women from minority communities.

                </a></h3>
              </div>
            
            </div>
        </div>
        <div class="col-md-4">
          
          <div class="cause shadow-sm">
            
              <a href="#" class="cause-link d-block">
                <img src="Gallary/134.jpg" alt="Image" class="img-fluid"/>
                <div class="custom-progress-wrap">
                  <span class="caption">80% complete</span>
                  <div class="custom-progress-inner">
                    <div class="custom-progress bg-info" style={{width: "80%"}}></div>
                  </div>
                </div>
              </a>

              <div class="px-3 pt-3 border-top-0 border border shadow-sm">
                <span class="badge-info py-1 small px-2 rounded mb-3 d-inline-block">Disabled and handicapped persons</span>
                <h3 class="mb-4"><a href="#"> People with disabilities or handicaps face unique challenges in accessing education, employment, healthcare, and other aspects of daily life. As a result, there are many organizations and programs that aim to support and empower disabled individuals, promote their rights and inclusion in society, and create a more accessible and equitable world.</a></h3>
              </div>
            </div>

        </div>
        <div class="col-md-4">
          
          <div class="cause shadow-sm">
            
              <a href="#" class="cause-link d-block">
                <img src="Gallary/125 - Copy.jpg" alt="Image" class="img-fluid"/>
                <div class="custom-progress-wrap">
                  <span class="caption">80% complete</span>
                  <div class="custom-progress-inner">
                    <div class="custom-progress bg-warning" style={{width: "80%"}}></div>
                  </div>
                </div>
              </a>

              <div class="px-3 pt-3 border-top-0 border border ">
                <span class="badge-warning py-1 small px-2 rounded mb-3 d-inline-block">Youth and adolescents</span>
                <h3 class="mb-4"><a href="#"> Youth and adolescents are important and dynamic segments of society, and there are many organizations and programs that aim to support and empower them as they navigate the challenges of growing up and transitioning to adulthood.
                  <br/>
                  1. Education and mentorship programs <br/>
                  2. Health and wellness programs
                </a></h3>
                
              </div>
            
            </div>

        </div>
      <div class="col-md-4">
          <div class="cause shadow-sm">
          
            <a href="#" class="cause-link d-block">
              <img src="Gallary/img38 - Copy.jpg" alt="Image" class="img-fluid"/>
              <div class="custom-progress-wrap">
                <span class="caption">80% complete</span>
                <div class="custom-progress-inner">
                  <div class="custom-progress bg-danger" style={{width: "80%"}}></div>
                </div>
              </div>
            </a>

            <div class=" pt-3 border-top-0 border border shadow-sm">
              <span class="badge-primary ml-3 py-1 small px-2 rounded mb-3 d-inline-block">Drug addicts</span>
              <h3 class="mb-4  ml-3"><a href="#">Drug and medicine addiction is a complex issue that affects individuals, families, and communities. Programs that aim to help individuals overcome drug and medicine addiction can vary widely in their approach and effectiveness.</a></h3>
            </div>
          
          </div>
      </div>
      <div class="col-md-4">
        
        <div class="cause shadow-sm">
          
            <a href="#" class="cause-link d-block">
              <img src="Gallary/125 - Copy.jpg" alt="Image" class="img-fluid"/>
              <div class="custom-progress-wrap">
                <span class="caption">80% complete</span>
                <div class="custom-progress-inner">
                  <div class="custom-progress bg-info" style={{width: "80%"}}></div>
                </div>
              </div>
            </a>



            <div class="px-3 pt-3 border-top-0 border border shadow-sm">
              <span class="badge-info py-1 small px-2 rounded mb-3 d-inline-block">Unemployed Youths</span>
                <div>
                    <h3 class="mb-4 text-justify"><a href="#"> Unemployment among youths can be a major challenge, as it can lead to a sense of hopelessness and lack of direction, 
                        and hinder the economic growth and development of communities.
                        <br/> we Follow:
                        <br/> 1. Job training and education programs:
                        <br/> 2. Career counseling and mentorship programs:
                        <br/> 3. Entrepreneurship programs:
                        </a>
                    </h3>
                </div>
            </div>
          </div>

      </div>
      <div class="col-md-4">
        
        <div class="cause shadow-sm">
          
            <a href="#" class="cause-link d-block">
              <img src="Gallary/img27.jpg" alt="Image" class="img-fluid"/>
              <div class="custom-progress-wrap">
                <span class="caption">80% complete</span>
                <div class="custom-progress-inner">
                  <div class="custom-progress bg-warning" style={{width: "80%"}}></div>
                </div>
              </div>
            </a>

            <div class="px-3 pt-3 border-top-0 border border ">
              <span class="badge-warning py-1 small px-2 rounded mb-3 d-inline-block">Tribal artisans</span>
              <h3 class="mb-4"><a href="#"> Tribal artisans are skilled craftsmen and women who create unique and intricate works of art using traditional techniques and materials. Many organizations and programs exist to support tribal artisans and promote their art and culture. </a></h3>
              
            </div>
          
          </div>

      </div>
      <div class="col-md-4">
        <div class="cause shadow-sm">
        
          <a href="#" class="cause-link d-block">
            <img src="Gallary/seniour.jpg" alt="Image" class="img-fluid"/>
            <div class="custom-progress-wrap">
              <span class="caption">80% complete</span>
              <div class="custom-progress-inner">
                <div class="custom-progress bg-danger" style={{width: "80%"}}></div>
              </div>
            </div>
          </a>

          <div class=" pt-3 border-top-0 border border shadow-sm">
            <span class="badge-primary ml-3 py-1 small px-2 rounded mb-3 d-inline-block">Neglected Senior citizens</span>
            <h3 class="mb-4  ml-3"><a href="#">Neglected senior citizens, particularly those who are isolated and vulnerable, can face a range of challenges related to their health, safety, and well-being. Many organizations and programs exist to support neglected seniors and help them live fulfilling and independent lives.</a></h3>
          </div>
        
        </div>
    </div>
    <div class="col-md-4">
      
      <div class="cause shadow-sm">
        
          <a href="#" class="cause-link d-block">
            <img src="Gallary/img1.jpg" alt="Image" class="img-fluid"/>
            <div class="custom-progress-wrap">
              <span class="caption">80% complete</span>
              <div class="custom-progress-inner">
                <div class="custom-progress bg-info" style={{width: "80%"}}></div>
              </div>
            </div>
          </a>

          <div class="px-3 pt-3 border-top-0 border border shadow-sm">
            <span class="badge-info py-1 small px-2 rounded mb-3 d-inline-block">Adolescents</span>
            <h3 class="mb-4"><a href="#"> Adolescents, or young people between the ages of 10 and 19, face a range of unique challenges as they navigate the transition from childhood to adulthood. Many organizations and programs exist to support adolescents and help them develop the skills and knowledge they need to thrive.</a></h3>
          </div>
        </div>

    </div>
    <div class="col-md-4">
      
      <div class="cause shadow-sm">
        
          <a href="#" class="cause-link d-block">
            <img src="Gallary/120.jpg" alt="Image" class="img-fluid"/>
            <div class="custom-progress-wrap">
              <span class="caption">80% complete</span>
              <div class="custom-progress-inner">
                <div class="custom-progress bg-warning" style={{width: "80%"}}></div>
              </div>
            </div>
          </a>

          <div class="px-3 pt-3 border-top-0 border border ">
            <span class="badge-warning py-1 small px-2 rounded mb-3 d-inline-block">Road Awareness</span>
            <h3 class="mb-4"><a href="#"> Road awareness programs aim to educate individuals and communities about safe and responsible road use, with the goal of reducing the number of road accidents and fatalities. programs provide training and instruction on safe driving practices, traffic laws, and defensive driving techniques. Driver education can be provided through schools, community organizations, or commercial driving schools.</a></h3>
            
          </div>
        
        </div>

    </div>

          


        </div>
        
        
      </div>
    </div>
   




      
   

    <div class="site-section">
      <div class="container">
        
            <div class="d-md-flex cta-20101 align-self-center bg-light p-5">
              <div class=""><h2 class="text-cursive">Helping the Homeless, Hungry, and Hurtings Women & Children's</h2></div>
              <div class="ml-auto"><a href="donate" class="btn btn-primary">Donate Now</a></div>
            </div>
        
      </div>
    </div>
    </div>

   
  )
}
